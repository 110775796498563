import React from "react"
import AnimalsFeed from "../components/AnimalsFeed"

const AnimalsFeedPage = () => {
  if (typeof window === "undefined") return null

  return <AnimalsFeed />
}

export default AnimalsFeedPage
