import { Button, Checkmark, Close, ForYou, IconButton, Refresh } from "nzk-react-components"
import React, { useState } from "react"
import FeedControls from "../FeedControls"
import Drawing from "../UI/Drawing"
import Loader from "../UI/Loader"
import { Actions, Content, NumberInput, Wrapper } from "./index.styles"
import useAnimalsFeed from "./useFeed"

const AnimalsFeed = () => {
  const {
    animals,
    loading,
    publishAll,
    publishAnimal,
    rejectAnimal,
    pageLength,
    updatePageLength,
    refresh,
    count,
  } = useAnimalsFeed(true)
  const [nbRequiredPress, setNbRequiredPress] = useState(3)

  if (loading) return <Loader />

  const onPublishAllPressed = () => {
    if (nbRequiredPress <= 1) {
      setNbRequiredPress(3)
      return publishAll()
    }
    setNbRequiredPress(nbRequiredPress - 1)
  }

  return (
    <Wrapper>
      <FeedControls
        title="Animals Feed"
        count={animals.length}
        total={count}
        filters={[]}
        pagination={
          <div>
            Pages of{" "}
            <NumberInput
              defaultValue={pageLength}
              onBlur={e => {
                updatePageLength(parseInt(e.target.value, 10) || 0)
              }}
            />{" "}
            animals
          </div>
        }
        actions={[
          <IconButton
            key='1'
            theme="confirm"
            size="small"
            icon={<Checkmark />}
            onClick={onPublishAllPressed}
          >
            Publish all ({nbRequiredPress})
          </IconButton>,
          <IconButton
            key='2'
            theme="primary"
            size="x-small"
            icon={<Refresh />}
            onClick={refresh}
          >
            Refresh
          </IconButton>,
        ]}
      />
      <Content>
        {animals.map(animal => (
          <Drawing
            {...animal}
            key={animal._id}
            actions={[
              <IconButton
                key="publish-star-drawing"
                size="x-small"
                theme="yellow"
                icon={<ForYou />}
                onClick={() => publishAnimal(animal, true)}
              >
                Feature
              </IconButton>,
              animal.status !== "published" ? (
                <IconButton
                  key="publish"
                  theme="confirm"
                  size="x-small"
                  icon={<Checkmark />}
                  onClick={() => publishAnimal(animal, false)}
                >
                  Publish
                </IconButton>
              ) : null,
              animal.status !== "archived" ? (
                <IconButton
                  key="flag"
                  theme="orange"
                  size="x-small"
                  icon={<Close />}
                  onClick={() => rejectAnimal(animal)}
                >
                  Reject
                </IconButton>
              ) : null,
            ].filter(d => d)}
            bottomActions={[
              animal.status !== "archived" && (
                <Button
                  round
                  key="reject-action"
                  primary
                  theme="red"
                  size="small"
                  onClick={() => rejectAnimal(animal)}
                >
                  <Close />
                </Button>
              ),
              animal.status !== "published" && (
                <Button
                  round
                  theme="confirm"
                  size="small"
                  key="publish-animal"
                  onClick={() => publishAnimal(animal, false)}
                >
                  <Checkmark />
                </Button>
              ),
            ].filter(d => d)}
          >
            {animal.text}
          </Drawing>
        ))}
      </Content>
      <Actions>
        <div>
          {animals.length} / {count} animals
        </div>
        <IconButton
          theme="confirm"
          icon={<Checkmark />}
          onClick={onPublishAllPressed}
          size="regular"
        >
          Publish all ({nbRequiredPress})
        </IconButton>
      </Actions>
    </Wrapper>
  )
}

AnimalsFeed.propTypes = {}

AnimalsFeed.defaultProps = {}

export default AnimalsFeed
